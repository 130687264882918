// React and Hooks
import { useCallback, useEffect, useMemo, useState } from "react";

// External Libraries
import { Line, Pie } from "react-chartjs-2";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";
import MKDatePicker from "components/MKDatePicker";
import MKTypography from "components/MKTypography";

// Material UI Components
import { Box, Divider, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Custom Components
import VideoLoader from "components/Animation/VideoLoader";

// Providers
import dashboardProvider from "providers/dashboardProvider";

// Utilities
import { areaFormatter } from "utils";

// Register Chart.js Components
Chart.register(ArcElement);
Chart.register(BarController);
Chart.register(BarElement);
Chart.register(CategoryScale);
Chart.register(Legend);
Chart.register(LinearScale);
Chart.register(LineController);
Chart.register(LineElement);
Chart.register(PointElement);
Chart.register(Tooltip);

function ChartsTab() {
  // Context and hooks
  const theme = useTheme();

  // Loading and error states
  const [loadingGeneral, setLoadingGeneral] = useState(false);
  const [loadingDate, setLoadingDate] = useState(false);

  // Data-specific states
  const [rentedUnitsData, setRentedUnitsData] = useState({
    rented: 0,
    available: 0,
  });
  const [rentedAreaData, setRentedAreaData] = useState({
    rented: 0,
    available: 0,
  });
  const [productIncomeData, setProductSalesData] = useState({
    labels: [],
    data: [],
  });
  const [rentalIncomeData, setRentalIncomeData] = useState({
    labels: [],
    expectedData: [],
    realData: [],
  });

  // General states
  const [startDate, setStartDate] = useState(
    new Date(new Date().getUTCFullYear(), 0, 1).toISOString()
  );
  const endingDate = new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth() + 1,
    0
  ).toISOString();
  const [endDate, setEndDate] = useState(endingDate);

  // Charts generales
  const fetchData = useCallback(async () => {
    setLoadingGeneral(true);
    const { data } = await dashboardProvider.getIndex();
    setRentedAreaData({
      rented: data.rented_surface,
      available: Number(data.surface - data.rented_surface),
    });

    setRentedUnitsData({
      rented: data.rented_units,
      available: data.available_units,
    });
    setLoadingGeneral(false);
  }, []);

  // Charts por tiempo
  const fetchDateData = useCallback(async () => {
    setLoadingDate(true);
    const {
      data: { products_income_by_month, rentals_income_by_month },
    } = await dashboardProvider.getChartsByDate(startDate, endDate);

    setProductSalesData({
      labels: products_income_by_month.map((item) =>
        new Date(item.date).toLocaleDateString("es-MX", {
          timeZone: "UTC",
          month: "short",
          year: "numeric",
        })
      ),
      data: products_income_by_month.map((item) => item.income),
    });

    setRentalIncomeData({
      labels: rentals_income_by_month.map((item) =>
        new Date(item.date).toLocaleDateString("es-MX", {
          timeZone: "UTC",
          month: "short",
          year: "numeric",
        })
      ),
      expectedData: rentals_income_by_month.map((item) => item.expected),
      realData: rentals_income_by_month.map((item) => item.real),
    });
    setLoadingDate(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchDateData();
  }, [fetchDateData]);

  const handleDateChange = (date, type) => {
    if (type === "start") {
      setStartDate(date[0]);
    } else {
      setEndDate(date[0]);
    }
  };

  // const productSales = {
  //   labels: ["Octubre", "Noviembre"],
  //   datasets: [
  //     {
  //       data: [7, 1],
  //       backgroundColor: [theme.palette.unitStatus.rentada],
  //       hoverOffset: 4,
  //     },
  //   ],
  // };

  const rentedDataOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${areaFormatter.format(context.parsed)} m2`;
          },
        },
      },
    },
  };

  const rentedUnits = useMemo(
    () => ({
      labels: ["Rentadas", "Disponibles"],
      datasets: [
        {
          data: [rentedUnitsData.rented, rentedUnitsData.available],
          backgroundColor: [
            theme.palette.unitStatus.rentada,
            theme.palette.unitStatus.disponible,
          ],
          hoverOffset: 4,
        },
      ],
    }),
    [rentedUnitsData, theme.palette.unitStatus]
  );

  const rentedArea = useMemo(
    () => ({
      labels: ["Rentada", "Disponible"],
      datasets: [
        {
          data: [rentedAreaData.rented, rentedAreaData.available],
          backgroundColor: [
            theme.palette.unitStatus.rentada,
            theme.palette.unitStatus.disponible,
          ],
          hoverOffset: 4,
        },
      ],
    }),
    [rentedAreaData, theme.palette.unitStatus]
  );

  const productPayments = useMemo(
    () => ({
      labels: productIncomeData.labels,
      datasets: [
        {
          label: "Ventas",
          data: productIncomeData.data,
          fill: false,
          borderColor: theme.palette.unitStatus.rentada,
          tension: 0.1,
        },
      ],
    }),
    [productIncomeData, theme.palette.unitStatus.rentada]
  );

  const rentalPayments = useMemo(
    () => ({
      labels: rentalIncomeData.labels,
      datasets: [
        {
          label: "Esperado",
          data: rentalIncomeData.expectedData,
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
        {
          label: "Real",
          data: rentalIncomeData.realData,
          fill: false,
          borderColor: theme.palette.unitStatus.rentada,
          tension: 0.1,
        },
      ],
    }),
    [rentalIncomeData, theme.palette.unitStatus.rentada]
  );

  return (
    <MKBox>
      <MKBox mb={3}>
        <Divider />
        <MKTypography variant="h4">General</MKTypography>
        <Grid item container spacing={2} justifyContent="space-evenly">
          {loadingGeneral ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <VideoLoader />
            </Box>
          ) : (
            <>
              <Grid item xs={6} md={6} lg={4}>
                <MKTypography variant="h6">
                  Porcentaje de ocupación (m2)
                </MKTypography>
                <Pie data={rentedArea} options={rentedDataOptions} />
              </Grid>
              <Grid item xs={6} md={6} lg={4}>
                <MKTypography variant="h6">
                  Porcentaje de ocupación (# unidades)
                </MKTypography>
                <Pie data={rentedUnits} />
              </Grid>
            </>
          )}
        </Grid>
      </MKBox>

      <MKBox mb={3}>
        <Divider />
        <MKTypography variant="h4">Por fecha</MKTypography>
        <MKBox mb={3} display="flex" gap={3} justifyContent="center">
          <MKDatePicker
            input={{
              value: startDate,
              label: "Fecha de inicio",
              InputLabelProps: { shrink: true },
            }}
            defaultValue={startDate}
            options={{ dateFormat: "d/m/Y", maxDate: endDate }}
            onChange={(date) => handleDateChange(date, "start")}
          />
          <MKDatePicker
            input={{
              value: endDate,
              label: "Fecha de fin",
              InputLabelProps: { shrink: true },
            }}
            defaultValue={endDate}
            options={{ dateFormat: "d/m/Y", minDate: startDate }}
            onChange={(date) => handleDateChange(date, "end")}
          />
        </MKBox>
        <Grid item container spacing={2}>
          {loadingDate ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <VideoLoader />
            </Box>
          ) : (
            <>
              <Grid item xs={6} md={6} lg={6}>
                <MKTypography variant="h6">
                  Ingresos mensuales de renta
                </MKTypography>
                <Line data={rentalPayments} />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <MKTypography variant="h6">
                  Ingresos mensuales de productos
                </MKTypography>
                <Line data={productPayments} />
              </Grid>
              {/* <Grid item xs={6} md={6} lg={6}>
                <MKTypography variant="h6">Ventas de productos</MKTypography>
                <Bar data={productSales} />
              </Grid> */}
            </>
          )}
        </Grid>
      </MKBox>
    </MKBox>
  );
}

export default ChartsTab;
