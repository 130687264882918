import axios from "axios";
import { getCurrentUserToken } from "utils";

class ExpenseProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/expenses`,
  });

  getExpenses(page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/", {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getExpense(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  createExpense(expenseParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.post("/", expenseParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  updateExpense(id, expenseParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.put(`/${id}`, expenseParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  deleteExpense(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.delete(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }
}

const expenseProviderInstance = new ExpenseProvider();
export default expenseProviderInstance;
