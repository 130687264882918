import React from "react";

//importar componentes de Material-UI
import { Box, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

//importar componentes de la aplicación
import { colors } from "assets/theme/base/colors";

//importar componentes de framer-motion
import { motion } from "framer-motion";
import {
  convertToMetersString,
  currencyFormatter,
  shortDateFormat,
} from "utils";

const Sidebar = ({ selectedUnit, closeSidebar }) => {
  if (!selectedUnit) return null;

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.5 }}
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        width: "30%",
        height: "80vh",
        backgroundColor: "#fff",
        zIndex: 10,
        borderLeft: `1px solid ${colors.grey[300]}`,
      }}
    >
      <Box
        sx={{
          padding: "16px",
          borderLeft: `1px solid ${colors.grey[300]}`,
          backgroundColor: colors.background.default,
          overflowY: "auto",
          height: "100%",
        }}
      >
        <Button
          onClick={closeSidebar}
          sx={{
            backgroundColor: colors.error.main,
            color: colors.white.main,
            "&:hover": {
              backgroundColor: colors.error.focus,
              color: colors.black.main,
            },
            marginBottom: "10px",
          }}
          startIcon={<CloseIcon />}
        ></Button>

        <Typography
          variant="h5"
          sx={{ color: colors.primary.main, marginBottom: "16px" }}
        >
          {selectedUnit.label}
        </Typography>

        <Typography variant="body1">
          <strong>Piso:</strong> {selectedUnit.floor}
        </Typography>
        <Typography variant="body1">
          <strong>Bloque:</strong> {selectedUnit.block}
        </Typography>
        <Typography variant="body1">
          <strong>Ancho:</strong> {convertToMetersString(selectedUnit.width)}
        </Typography>
        <Typography variant="body1">
          <strong>Largo:</strong> {convertToMetersString(selectedUnit.height)}
        </Typography>
        <Typography variant="body1">
          <strong>Precio:</strong>{" "}
          {currencyFormatter.format(selectedUnit.default_price)}
        </Typography>
        <Typography variant="body1">
          <strong>Status:</strong> {selectedUnit.status}
        </Typography>

        {/* Mostrar información adicional si es irregular */}
        {selectedUnit.is_irregular && (
          <>
            <Typography variant="body1" sx={{ marginTop: "16px" }}>
              <strong>Tipo:</strong> Unidad Irregular
            </Typography>
            <Typography variant="body1">
              <strong>Metros cuadrados:</strong>{" "}
              {selectedUnit.square_meters
                ? selectedUnit.square_meters + " m²"
                : "No disponible"}
            </Typography>
          </>
        )}

        {/* Mostrar información adicional si hay un alquiler activo */}
        {selectedUnit.active_rental && (
          <>
            <Typography variant="body1" sx={{ marginTop: "16px" }}>
              <strong>Contrato #:</strong> {selectedUnit.active_rental.id}
            </Typography>
            <Typography variant="body1">
              <strong>Quién lo renta:</strong>{" "}
              {selectedUnit.active_rental.user_fiscal_name}
            </Typography>
            <Typography variant="body1">
              <strong>Desde cuándo se renta:</strong>{" "}
              {shortDateFormat(selectedUnit.active_rental.start_date)}
            </Typography>
          </>
        )}
      </Box>
    </motion.div>
  );
};

export default Sidebar;
