// React Router imports
import { useNavigate } from "react-router-dom";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material UI Components
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

function CustomerInfoTab({ customer, cfdiUses, fiscalRegimes }) {
  // Context and hooks
  const navigate = useNavigate();

  function getCfdiUseName(cfdiUse) {
    const cfdi = cfdiUses.find((use) => use.Value === String(cfdiUse));
    return cfdi ? `${cfdi.Value} - ${cfdi.Name}` : "Desconocido";
  }
  function getFiscalRegimeName(fiscalRegimeNumber) {
    const regime = fiscalRegimes.find(
      (regime) => regime.Value === String(fiscalRegimeNumber)
    );
    return regime ? `${regime.Value} - ${regime.Name}` : "Desconocido";
  }

  const handleEditClick = () => {
    navigate(`/customers/${customer.id}/edit`);
  };

  return (
    <MKBox p={3}>
      <MKBox mb={3}>
        <MKBox mb={3}>
          <MKButton
            variant="contained"
            color="primary"
            onClick={handleEditClick}
          >
            Editar Información
          </MKButton>
        </MKBox>
        <MKTypography variant="h4">Información de contacto</MKTypography>
        <Divider />
        <Grid container spacing={3}>
          {/* left side */}
          <Grid item xs={12} md={6}>
            <MKTypography variant="h5">Nombre:</MKTypography>
            <MKTypography variant="body1">
              {customer.first_name} {customer.last_name}
            </MKTypography>
            <MKTypography variant="h5">Email:</MKTypography>
            <MKTypography variant="body1">{customer.email}</MKTypography>
            <MKTypography variant="h5">Teléfono:</MKTypography>
            <MKTypography variant="body1">{customer.phone}</MKTypography>
          </Grid>
          {/* right side */}
          <Grid item xs={12} md={6}>
            <MKTypography variant="h5">Estatus:</MKTypography>
            <MKTypography variant="body1">{customer.status}</MKTypography>
          </Grid>
        </Grid>
      </MKBox>

      {/* Dirección */}
      <MKBox mb={3}>
        <MKTypography variant="h4">Dirección</MKTypography>
        <Divider />
        <Grid container spacing={3}>
          {/* left side */}
          <Grid item xs={12} md={6}>
            <MKTypography variant="h5">Calle:</MKTypography>
            <MKTypography variant="body1">{customer.street}</MKTypography>
            <MKTypography variant="h5">Número interior:</MKTypography>
            <MKTypography variant="body1">
              {customer.interior_number || "N/A"}
            </MKTypography>
            <MKTypography variant="h5">Ciudad:</MKTypography>
            <MKTypography variant="body1">{customer.city}</MKTypography>
            <MKTypography variant="h5">Estado:</MKTypography>
            <MKTypography variant="body1">{customer.state}</MKTypography>
          </Grid>
          {/* right side */}
          <Grid item xs={12} md={6}>
            <MKTypography variant="h5">Número exterior:</MKTypography>
            <MKTypography variant="body1">
              {customer.exterior_number}
            </MKTypography>
            <MKTypography variant="h5">Colonia:</MKTypography>
            <MKTypography variant="body1">{customer.neighborhood}</MKTypography>
            <MKTypography variant="h5">Código Postal:</MKTypography>
            <MKTypography variant="body1">{customer.zip_code}</MKTypography>
            <MKTypography variant="h5">País:</MKTypography>
            <MKTypography variant="body1">{customer.country}</MKTypography>
          </Grid>
        </Grid>
      </MKBox>

      {/* Datos fiscales */}
      {customer.require_invoice && (
        <MKBox mb={3}>
          <MKTypography variant="h4">Datos fiscales</MKTypography>
          <Divider />
          <Grid container spacing={3}>
            {/* left side */}
            <Grid item xs={12} md={6}>
              <MKTypography variant="h5">Razón Social:</MKTypography>
              <MKTypography variant="body1">
                {customer.fiscal_name}
              </MKTypography>
              <MKTypography variant="h5">RFC:</MKTypography>
              <MKTypography variant="body1">
                {customer.fiscal_number}
              </MKTypography>
            </Grid>
            {/* right side */}
            <Grid item xs={12} md={6}>
              <MKTypography variant="h5">
                Uso de CFDI predeterminado:
              </MKTypography>
              <MKTypography variant="body1">
                {" "}
                {getCfdiUseName(customer.cfdi_use)}
              </MKTypography>
              <MKTypography variant="h5">Régimen fiscal:</MKTypography>
              <MKTypography variant="body1">
                {getFiscalRegimeName(customer.fiscal_regime)}
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
      )}
    </MKBox>
  );
}

export default CustomerInfoTab;
