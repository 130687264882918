// React imports
import { useEffect, useMemo, useState } from "react";

// React Router imports
import { Link, useLocation } from "react-router-dom";

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";

// Custom Components
import NavTabs from "components/NavTabs";
import TabPanel from "components/NavTabs/TabPanel";
import Toolbar from "components/Toolbar";

// Pages
import DepositInvoicesTable from "pages/Invoices/Tabs/depositInvoicesTable";
import RentInvoicesTable from "pages/Invoices/Tabs/rentalInvoicesTable";

// Utilities
import { formatDDMMYYYY } from "utils";

function TabIndex({ defaultTab = 0, permanentQuery, urlQuery }) {
  // Context and hooks
  const location = useLocation();

  // Derived states or memoized values
  const state = useMemo(() => location.state || {}, [location.state]);
  const tabs = useMemo(
    () => [
      { label: "Renta", icon: "house" },
      { label: "Depósito", icon: "attach_money" },
    ],
    []
  );

  // General states
  const [tab, setTab] = useState(() => {
    return localStorage.getItem("activeTab")
      ? Number(localStorage.getItem("activeTab"))
      : state.defaultTab ?? defaultTab;
  });

  const [startDate, setStartDate] = useState(
    formatDDMMYYYY(
      new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1)
    )
  );

  const [endDate, setEndDate] = useState(
    formatDDMMYYYY(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    )
  );

  const [query, setQuery] = useState(
    Object.keys(urlQuery).length > 0
      ? urlQuery
      : {
          end_date_gteq: startDate,
          end_date_lteq: endDate,
        }
  );

  // Handlers
  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  const handleDateChange = (date, type) => {
    if (type === "start") {
      setStartDate(formatDDMMYYYY(new Date(date[0])));
    } else {
      setEndDate(formatDDMMYYYY(new Date(date[0])));
    }
  };

  useEffect(() => {
    localStorage.setItem("activeTab", tab);
  }, [tab]);

  return (
    <>
      <Toolbar
        setQuery={(query) =>
          setQuery({
            ...query,
            end_date_gteq: startDate,
            end_date_lteq: endDate,
          })
        }
        ransackQuery="customer_first_name_or_customer_last_name_or_unit_name_or_status_cont"
        newItemButton={
          <Box display="flex" gap={2}>
            <MKButton
              variant="contained"
              size="small"
              color="primary"
              component={Link}
              to={`/invoices/new?type=${tab === 0 ? "renta" : "depósito"}`}
            >
              + Agregar cobro
            </MKButton>
          </Box>
        }
      >
        <MKBox display="flex" gap="1rem" alignItems="center">
          <MKDatePicker
            input={{
              value: startDate,
              label: "Fecha de inicio",
              InputLabelProps: { shrink: true },
            }}
            defaultValue={startDate}
            options={{ dateFormat: "d/m/Y", maxDate: endDate }}
            onChange={(date) => handleDateChange(date, "start")}
          />
          <MKDatePicker
            input={{
              value: endDate,
              label: "Fecha de fin",
              InputLabelProps: { shrink: true },
            }}
            defaultValue={endDate}
            options={{ dateFormat: "d/m/Y", minDate: startDate }}
            onChange={(date) => handleDateChange(date, "end")}
          />
        </MKBox>
      </Toolbar>

      <Grid item xs={12} lg={12}>
        <NavTabs tabs={tabs} active={tab} onChange={handleTabChange} />
      </Grid>

      <Grid item xs={12} lg={12}>
        <TabPanel value={tab} index={0}>
          <RentInvoicesTable permanentQuery={permanentQuery} query={query} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <DepositInvoicesTable permanentQuery={permanentQuery} query={query} />
        </TabPanel>
      </Grid>
    </>
  );
}

export default TabIndex;
