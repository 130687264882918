// React and Hooks
import { useEffect, useMemo, useState } from "react";

// External Libraries
import { useLocation, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI Components
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

// Providers
import customersProvider from "providers/customersProvider";
import invoicesProvider from "providers/invoicesProvider";

// Custom Components
import VideoLoader from "components/Animation/VideoLoader";
import NavTabs from "components/NavTabs";
import TabPanel from "components/NavTabs/TabPanel";

// Pages
import CustomerInfoTab from "pages/Customers/tabs/customerInfo";
import CustomerRentalsTab from "pages/Customers/tabs/customerRentals";
import CustomerInvoicesTab from "pages/Customers/tabs/customerInvoices";
import CustomerPaymentsTab from "pages/Customers/tabs/customerPayments";

function ShowCustomerPage() {
  // Context and hooks
  const { id } = useParams();
  const { hash } = useLocation();

  // Derived states or memoized values
  const tabs = useMemo(
    () => [
      { label: "Información", icon: "info" },
      { label: "Contratos", icon: "home" },
      { label: "Cobros", icon: "receipt" },
      { label: "Pagos", icon: "payment" },
    ],
    []
  );

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [loadingTab, setLoadingTab] = useState(true);

  // General states
  const [tab, setTab] = useState(0);

  // Data-specific states
  const [customer, setCustomer] = useState(null);
  const [cfdiUses, setCfdiUses] = useState([]);
  const [fiscalRegimes, setFiscalRegimes] = useState([]);

  useEffect(() => {
    setLoadingTab(true);
    if (hash) {
      const tab = tabs.findIndex((tab) => tab.label === hash.replace("#", ""));
      if (tab >= 0) {
        setTab(tab);
      }
    }
    setLoadingTab(false);
  }, [hash, tabs]);

  useEffect(() => {
    async function fetchCustomerAndRegimes() {
      try {
        setLoading(true);
        const customerResponse = await customersProvider.getCustomer(id);
        const fetchedCustomer = customerResponse.data;
        setCustomer(fetchedCustomer);

        if (fetchedCustomer?.fiscal_number) {
          const [cfdiUsesResponse, fiscalRegimesResponse] = await Promise.all([
            invoicesProvider.getCfdiUses(fetchedCustomer.fiscal_number),
            invoicesProvider.getFiscalRegimens(fetchedCustomer.fiscal_number),
          ]);

          setCfdiUses(cfdiUsesResponse.data);
          setFiscalRegimes(fiscalRegimesResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchCustomerAndRegimes();
  }, [id]);

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  return (
    <MainLayout
      title={`${
        customer
          ? `${customer.first_name} ${customer.last_name}`
          : "Cargando..."
      }`}
    >
      <Grid item xs={12} lg={12}>
        {!loadingTab && (
          <NavTabs tabs={tabs} active={tab} onChange={handleTabChange} />
        )}
      </Grid>
      <Grid item xs={12} lg={12}>
        {loading || !customer ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="15vh"
          >
            <VideoLoader />
          </Box>
        ) : (
          <>
            <TabPanel value={tab} index={0}>
              <CustomerInfoTab
                customer={customer}
                cfdiUses={cfdiUses}
                fiscalRegimes={fiscalRegimes}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <CustomerRentalsTab customer={customer} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <CustomerInvoicesTab customer={customer} />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <CustomerPaymentsTab customer={customer} />
            </TabPanel>
          </>
        )}
      </Grid>
    </MainLayout>
  );
}

export default ShowCustomerPage;
