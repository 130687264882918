export * from "./consts";

export function getCurrentUserToken() {
  return JSON.parse(sessionStorage.getItem("currentUser")).token;
}

export const currencyFormatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export const areaFormatter = new Intl.NumberFormat("es-MX", {
  style: "decimal",
  maximumFractionDigits: 2,
});

// Formato de fecha corta, en UTC
export const formatDDMMYYYY = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const day = String(d.getUTCDate()).padStart(2, "0");
  const month = String(d.getUTCMonth() + 1).padStart(2, "0");
  const year = d.getUTCFullYear();
  return `${day}/${month}/${year}`;
};

export const shortDateFormat = (date) => {
  if (
    date === null ||
    date === undefined ||
    date === "" ||
    date === "0000-00-00" ||
    date === "0000-00-00 00:00:00"
  ) {
    return "";
  }

  return new Date(date).toLocaleDateString("es-MX", {
    timeZone: "UTC",
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const numericDateFormat = (date) => {
  if (
    date === null ||
    date === undefined ||
    date === "" ||
    date === "0000-00-00" ||
    date === "0000-00-00 00:00:00"
  ) {
    return "";
  }

  return new Date(date).toLocaleDateString("es-MX", {
    timeZone: "UTC",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
};

export const longDateFormat = (date) => {
  if (
    date === null ||
    date === undefined ||
    date === "" ||
    date === "0000-00-00" ||
    date === "0000-00-00 00:00:00"
  ) {
    return "";
  }

  return new Date(date).toLocaleDateString("es-MX", {
    timeZone: "UTC",
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

export function calculateBillingPeriod(startDate, endDate) {
  if (!startDate) {
    startDate = new Date(endDate);
    startDate.setUTCMonth(startDate.getUTCMonth() - 1);
  }

  return `${shortDateFormat(startDate)} - ${shortDateFormat(endDate)}`;
}

// Convertir de cm a metros, para el mapa
export function convertToMetersString(value) {
  return `${(Number(value) / 100).toFixed(2)} m`;
}
