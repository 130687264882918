// React imports
import { useCallback, useEffect, useMemo, useState } from "react";

// External Libraries (React Router imports)
import { Link, useLocation, useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI Components
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";
import MKTypography from "components/MKTypography";
import Toolbar from "components/Toolbar";

// Custom Components
import ActionsMenu from "components/Menu";
import Table from "components/Table";
import VideoLoader from "components/Animation/VideoLoader";
import customAlert from "components/Alerts/CustomAlert";

// Providers
import expenseCategoriesProvider from "providers/expenseCategoriesProvider";
import expenseProvider from "providers/expenseProvider";

// Utilities
import {
  currencyFormatter,
  formatDDMMYYYY,
  PAYMENT_FORMS,
  shortDateFormat,
} from "utils";

function ExpensePage() {
  // Context and hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Derived states or memoized values
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}`] = value;
      return acc;
    }, {});
  }, [location.search]);

  const columns = useMemo(
    () => [
      { name: "fecha", align: "left" },
      { name: "cantidad", align: "left" },
      { name: "forma de pago", align: "left" },
      { name: "categoria", align: "left" },
      { name: "concepto", align: "left" },
      { name: "notas", align: "left" },
      { name: "opciones", align: "center" },
    ],
    []
  );

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  // General states
  const [startDate, setStartDate] = useState(
    formatDDMMYYYY(
      new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1)
    )
  );

  const [endDate, setEndDate] = useState(
    formatDDMMYYYY(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    )
  );

  const [state, setState] = useState({
    expenses: [],
    rows: [],
    total: 0,
    page: 0,
    query:
      Object.keys(searchParams).length > 0
        ? searchParams
        : { date_gteq: startDate, date_lteq: endDate },
  });

  // Data-specific states
  const [categoryMap, setCategoryMap] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setErrorMessage(null);
      try {
        const { data: expenseCategoriesData } =
          await expenseCategoriesProvider.getExpenseCategories();
        const categories = expenseCategoriesData?.data || [];

        const map = categories.reduce((acc, category) => {
          acc[category.id] = category.name;
          return acc;
        }, {});
        setCategoryMap(map);
      } catch (error) {
        console.error("Error fetching expense categories data", error);
        let errorMsg =
          "Hubo un problema al cargar los datos de las categorías de gastos. Por favor, intenta de nuevo.";
        if (error.response && error.response.data) {
          errorMsg = Array.isArray(error.response.data)
            ? error.response.data.join(", ")
            : error.response.data;
        }
        setErrorMessage(errorMsg);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Fetches expense data and updates the state with the list of customers and total count
  const fetchData = useCallback(async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const {
        data: { data: expensesData, total },
      } = await expenseProvider.getExpenses(state.page + 1, state.query);
      setState((prevState) => ({
        ...prevState,
        expenses: expensesData,
        total,
      }));
    } catch (error) {
      console.error("Error fetching expense data", error);
      let errorMsg =
        "Hubo un problema al cargar los datos de los gastos. Por favor, intenta de nuevo.";
      if (error.response && error.response.data) {
        errorMsg = Array.isArray(error.response.data)
          ? error.response.data.join(", ")
          : error.response.data;
      }
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  }, [state.page, state.query]);

  // Triggers fetchData when page or query changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback((_ev, page) => {
    setState((prevState) => ({ ...prevState, page }));
  }, []);

  const handleDateChange = (date, type) => {
    if (type === "start") {
      setStartDate(formatDDMMYYYY(new Date(date[0])));
    } else {
      setEndDate(formatDDMMYYYY(new Date(date[0])));
    }
  };

  const handleDeleteExpense = useCallback(
    (id) => {
      customAlert({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer. Se perderá toda la información del gasto.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await expenseProvider.deleteExpense(id);
            fetchData();
            customAlert({
              title: "Eliminada!",
              text: "El gasto ha sido eliminado",
              icon: "success",
              confirmButtonColor: "#3085d6",
            });
            navigate("/expenses");
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Ocurrió un error al intentar eliminar el gasto",
              icon: "error",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    [fetchData, navigate]
  );

  useEffect(() => {
    if (state.expenses && state.expenses.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.expenses.map((expenses) => ({
          fecha: (
            <MKTypography variant="button" fontWeight="medium">
              {shortDateFormat(expenses.date)}
            </MKTypography>
          ),
          cantidad: (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(expenses.amount)}
            </MKTypography>
          ),
          "forma de pago": (
            <MKTypography variant="button" fontWeight="medium">
              {expenses.payment_form
                ? PAYMENT_FORMS[expenses.payment_form]
                : "Forma de pago desconocida"}
            </MKTypography>
          ),
          categoria: (
            <MKTypography variant="button" fontWeight="medium">
              {categoryMap[expenses.expense_category_id] ||
                "Categoría desconocida"}
            </MKTypography>
          ),

          concepto: (
            <MKTypography variant="button" fontWeight="medium">
              {expenses.concept}
            </MKTypography>
          ),
          notas: (
            <MKTypography variant="button" fontWeight="medium">
              {expenses.notes}
            </MKTypography>
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  {
                    text: "Editar",
                    icon: <EditIcon />,
                    onClick: () => navigate(`/expenses/${expenses.id}/edit`),
                  },
                  {
                    text: "Eliminar",
                    icon: <DeleteIcon />,
                    onClick: () => handleDeleteExpense(expenses.id),
                  },
                ]}
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [state.expenses, handleDeleteExpense, navigate, categoryMap]);

  return (
    <MainLayout title="Gastos">
      <Toolbar
        setQuery={(query) =>
          setState((prevState) => ({
            ...prevState,
            query: { ...query, date_gteq: startDate, date_lteq: endDate },
          }))
        }
        ransackQuery="expense_category_name_or_concept_or_notes_cont"
        newItemButton={
          <Box display="flex" gap={2}>
            <MKButton
              variant="contained"
              size="small"
              color="primary"
              component={Link}
              to="/expenses/new"
            >
              + Agregar gastos
            </MKButton>
          </Box>
        }
      >
        <MKBox display="flex" gap="1rem" alignItems="center">
          <MKDatePicker
            input={{
              value: startDate,
              label: "Fecha de inicio",
              InputLabelProps: { shrink: true },
            }}
            defaultValue={startDate}
            options={{ dateFormat: "d/m/Y", maxDate: endDate }}
            onChange={(date) => handleDateChange(date, "start")}
          />
          <MKDatePicker
            input={{
              value: endDate,
              label: "Fecha de fin",
              InputLabelProps: { shrink: true },
            }}
            defaultValue={endDate}
            options={{ dateFormat: "d/m/Y", minDate: startDate }}
            onChange={(date) => handleDateChange(date, "end")}
          />
        </MKBox>
      </Toolbar>

      {errorMessage && (
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "15vh" }}
        >
          <MKAlert color="error" dismissible={true}>
            {errorMessage}
          </MKAlert>
        </Grid>
      )}

      <Grid container item xs={12} lg={12}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="15vh"
          >
            <VideoLoader />
          </Box>
        ) : state.rows.length === 0 ? (
          <MKTypography variant="h6" fontWeight="medium">
            No se encontraron gastos
          </MKTypography>
        ) : (
          <Table
            columns={columns}
            rows={state.rows}
            total={state.total}
            page={state.page}
            handlePageChange={handlePageChange}
          />
        )}
      </Grid>
    </MainLayout>
  );
}

export default ExpensePage;
