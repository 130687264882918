// React imports
import { useContext, useState } from "react";

// React Router imports
import { useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material UI Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// Providers
import unitsProvider from "providers/unitsProvider";

// Contexts
import SessionContext from "contexts/SessionContext";

function NewUnitPage() {
  // Context and hooks
  const { currentUser } = useContext(SessionContext);
  const navigate = useNavigate();

  // Loading and error states
  const [formErrors, setFormErrors] = useState("");

  // State for unit data
  const [unit, setUnit] = useState({
    name: "",
    floor: "",
    block: "",
    width: "",
    height: "",
    length: "",
    default_price: "",
    square_meters: "",
    is_irregular: false,
    status: "disponible",
    subsidiary_id: currentUser.subsidiary.id,
  });

  // Focus states
  const [focusedWidth, setFocusedWidth] = useState(false);
  const [focusedHeight, setFocusedHeight] = useState(false);
  const [focusedLength, setFocusedLength] = useState(false);
  const [focusedPrice, setFocusedPrice] = useState(false);

  // State for irregular checkbox
  const [isIrregular, setIsIrregular] = useState(false);

  // Handlers
  const handleFocusWidth = () => setFocusedWidth(true);
  const handleBlurWidth = () => setFocusedWidth(false);
  const handleFocusHeight = () => setFocusedHeight(true);
  const handleBlurHeight = () => setFocusedHeight(false);
  const handleFocusLength = () => setFocusedLength(true);
  const handleBlurLength = () => setFocusedLength(false);
  const handleFocusPrice = () => setFocusedPrice(true);
  const handleBlurPrice = () => setFocusedPrice(false);

  // Handles changes in form input fields and updates unit state
  const handleInputChange = ({ target: { name, value } }) => {
    const newValue = [
      "width",
      "height",
      "length",
      "square_meters",
      "default_price",
    ].includes(name)
      ? value === ""
        ? ""
        : Math.max(0, Number(value)).toString()
      : value;

    setUnit((prevUnit) => ({
      ...prevUnit,
      [name]: newValue,
    }));
  };

  // Handles changes in the irregular checkbox and updates unit state
  const handleIrregularChange = (event) => {
    const { checked } = event.target;
    setIsIrregular(checked);
    setUnit((prevUnit) => ({
      ...prevUnit,
      is_irregular: checked,
      square_meters: checked ? prevUnit.square_meters : "", // Limpiar square_meters si no es irregular
    }));
  };

  /// Submits the form after validation and updates the unit data
  const submitForm = async (event) => {
    event.preventDefault();

    try {
      await unitsProvider.createUnit(unit);
      navigate("/units", {
        state: { feedback: "Unidad creada correctamente" },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  return (
    <MainLayout title="Nueva Unidad">
      <MKBox component="section" py={12}>
        <Container>
          <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
            <MKBox width="100%" component="form" onSubmit={submitForm}>
              {formErrors && (
                <MKAlert color="error" dismissible={true}>
                  {formErrors}
                </MKAlert>
              )}
              <MKBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MKInput
                      name="name"
                      label="Nombre"
                      value={unit.name}
                      onChange={handleInputChange}
                      required={true}
                      variant="standard"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      name="floor"
                      label="Piso"
                      value={unit.floor}
                      onChange={handleInputChange}
                      required={false}
                      variant="standard"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      name="block"
                      label="Bloque"
                      value={unit.block}
                      onChange={handleInputChange}
                      required={false}
                      variant="standard"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MKInput
                      name="width"
                      label="Ancho"
                      value={unit.width}
                      onChange={handleInputChange}
                      onFocus={handleFocusWidth}
                      onBlur={handleBlurWidth}
                      required={false}
                      variant="standard"
                      type="number"
                      fullWidth
                      InputProps={{
                        endAdornment:
                          focusedWidth || unit.width ? (
                            <InputAdornment
                              position="end"
                              disableTypography={true}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              mts
                            </InputAdornment>
                          ) : null,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MKInput
                      name="height"
                      label="Alto"
                      value={unit.height}
                      onChange={handleInputChange}
                      onFocus={handleFocusHeight}
                      onBlur={handleBlurHeight}
                      required={false}
                      variant="standard"
                      type="number"
                      fullWidth
                      InputProps={{
                        endAdornment:
                          focusedHeight || unit.height ? (
                            <InputAdornment
                              position="end"
                              disableTypography={true}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              mts
                            </InputAdornment>
                          ) : null,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MKInput
                      name="length"
                      label="Largo"
                      value={unit.length}
                      onChange={handleInputChange}
                      onFocus={handleFocusLength}
                      onBlur={handleBlurLength}
                      required={false}
                      variant="standard"
                      type="number"
                      fullWidth
                      InputProps={{
                        endAdornment:
                          focusedLength || unit.length ? (
                            <InputAdornment
                              position="end"
                              disableTypography={true}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              mts
                            </InputAdornment>
                          ) : null,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="default_price"
                      label="Precio"
                      value={unit.default_price}
                      onChange={handleInputChange}
                      onFocus={handleFocusPrice}
                      onBlur={handleBlurPrice}
                      required={true}
                      variant="standard"
                      type="number"
                      fullWidth
                      InputProps={{
                        startAdornment:
                          focusedPrice || unit.default_price ? (
                            <InputAdornment
                              position="start"
                              disableTypography={true}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              $
                            </InputAdornment>
                          ) : null,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isIrregular}
                          onChange={handleIrregularChange}
                        />
                      }
                      label="Unidad irregular"
                    />
                  </Grid>
                  {isIrregular && (
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="square_meters"
                        label="Metros cuadrados"
                        value={unit.square_meters}
                        onChange={handleInputChange}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              m²
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid container item justifyContent="center" xs={12} my={2}>
                  <MKButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Crear Unidad
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </MainLayout>
  );
}

export default NewUnitPage;
