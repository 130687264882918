import axios from "axios";
import { getCurrentUserToken } from "utils";

class ExpenseCategoriesProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/expense_categories`,
  });

  getExpenseCategories(page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/", {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getExpenseCategory(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  createExpenseCategory(expenseCategoryParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.post("/", expenseCategoryParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  updateExpenseCategory(id, expenseCategoryParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.put(`/${id}`, expenseCategoryParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  deleteExpenseCategory(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.delete(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }
}

const expenseCategoriesProviderInstance = new ExpenseCategoriesProvider();
export default expenseCategoriesProviderInstance;
