// React imports
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material UI Components
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Custom Components
import ActionsMenu from "components/Menu";
import Table from "components/Table";
import VideoLoader from "components/Animation/VideoLoader";
import customAlert from "components/Alerts/CustomAlert";

// External Libraries
import Swal from "sweetalert2";

// Providers
import subsidiaryProvider from "providers/subsidiaryProvider";
import usersProvider from "providers/usersProvider";

// Contexts
import SessionContext from "contexts/SessionContext";

function ManagersTable({
  managers = [],
  total,
  page,
  onPageChange,
  refreshData,
}) {
  // Context and hooks
  const { currentUser } = useContext(SessionContext);

  // Derived states or memoized values
  const columns = useMemo(
    () => [
      { name: "nombre", align: "left" },
      { name: "email", align: "left" },
      { name: "sucursal", align: "center" },
      { name: "estatus", align: "center" },
      { name: "bloquear", align: "center" },
      { name: "opciones", align: "center" },
    ],
    []
  );

  // Loading and error states
  const [loading, setLoading] = useState(false);

  // General states
  const [sucursales, setSucursales] = useState([]);
  const [state, setState] = useState({
    users: managers,
    rows: [],
    total: managers.length,
    page: page,
    query: "",
    blockedUsers: {},
  });

  useEffect(() => {
    const fetchSubsidiaries = async () => {
      try {
        const response = await subsidiaryProvider.getSubsidiaries();
        const subsidiariesData = response.data.data || [];
        setSucursales(Array.isArray(subsidiariesData) ? subsidiariesData : []);
      } catch (error) {
        console.error("Error al obtener las sucursales:", error);
      }
    };

    if (currentUser.role_id === 2) {
      setSucursales([currentUser.subsidiary]);
    } else {
      fetchSubsidiaries();
    }
  }, [currentUser]);

  useEffect(() => {
    setLoading(true);
    if (managers && managers.length > 0) {
      const initialBlockedUsers = {};
      managers.forEach((user) => {
        initialBlockedUsers[user.id] = user.status === "bloqueado";
      });

      setState((prevState) => ({
        ...prevState,
        users: managers,
        blockedUsers: initialBlockedUsers,
      }));
    }
    setLoading(false);
  }, [managers]);

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback(
    (_ev, newPage) => {
      onPageChange(newPage);
    },
    [onPageChange]
  );

  const handleCreateUser = useCallback(() => {
    customAlert({
      title: "Crear nuevo gerente",
      html:
        '<div style="margin-bottom: 5px;">' +
        '<input id="first_name" class="swal2-input" placeholder="Nombre" style="width: 60%; padding: 10px; font-size: 16px; box-sizing: border-box;">' +
        "</div>" +
        '<div style="margin-bottom: 5px;">' +
        '<input id="last_name" class="swal2-input" placeholder="Apellido" style="width: 60%; padding: 10px; font-size: 16px; box-sizing: border-box;">' +
        "</div>" +
        '<div style="margin-bottom: 20px;">' +
        '<input id="email" class="swal2-input" placeholder="Email" style="width: 60%; padding: 10px; font-size: 16px; box-sizing: border-box;">' +
        "</div>" +
        "<div>" +
        '<select id="subsidiary" class="swal2-input" style="width: 60%; padding: 10px; font-size: 16px; box-sizing: border-box; height: 40px;">' +
        "<option disabled selected>Selecciona una sucursal</option>" +
        (sucursales.length > 0
          ? sucursales
              .map(
                (sucursal) =>
                  `<option value="${sucursal.id}" ${
                    currentUser.subsidiary_id === sucursal.id && "selected"
                  } >${sucursal.name}</option>`
              )
              .join("")
          : "<option disabled>No hay sucursales disponibles</option>") +
        "</select>" +
        "</div>",
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Crear",
      cancelButtonText: "Cancelar",
      preConfirm: () => {
        const first_name = document.getElementById("first_name").value;
        const last_name = document.getElementById("last_name").value;
        const email = document.getElementById("email").value;
        const subsidiary_id = document.getElementById("subsidiary").value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!first_name || first_name.length < 3) {
          return Swal.showValidationMessage(
            "El nombre debe tener al menos 3 caracteres"
          );
        }
        if (!last_name || last_name.length < 2) {
          return Swal.showValidationMessage(
            "El apellido debe tener al menos 2 caracteres"
          );
        }
        if (!email || !emailRegex.test(email)) {
          return Swal.showValidationMessage("Debes ingresar un email válido");
        }
        if (!subsidiary_id) {
          return Swal.showValidationMessage(
            "Debes ingresar una sucursal válida"
          );
        }

        return { first_name, last_name, email, subsidiary_id };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { first_name, last_name, email, subsidiary_id } = result.value;
        const defaultPassword = "UGManager";
        setLoading(true);

        try {
          const newUser = await usersProvider.createUser({
            first_name,
            last_name,
            email,
            password: defaultPassword,
            subsidiary_id,
            status: "Activo",
            role_id: 2,
          });

          setState((prevState) => ({
            ...prevState,
            users: [...prevState.users, newUser.data],
            total: prevState.total + 1,
          }));

          customAlert({
            title: "Gerente creado",
            text: `Se ha creado el gerente: ${first_name} ${last_name}`,
            icon: "success",
          });
          refreshData();
        } catch (error) {
          const errorMessage =
            error.response?.data?.[0] ||
            error.message ||
            "Hubo un problema al crear el gerente.";
          customAlert({
            title: "Error",
            text: errorMessage,
            icon: "error",
          });
        } finally {
          setLoading(false);
        }
      }
    });
  }, [setState, sucursales, setLoading, refreshData, currentUser]);

  const handleNameEdit = useCallback(
    (userId, currentFirstName, currentLastName) => {
      customAlert({
        title: "Actualizar nombre",
        html:
          '<input id="first_name" class="swal2-input" placeholder="Nombre" value="' +
          currentFirstName +
          '">' +
          '<input id="last_name" class="swal2-input" placeholder="Apellido" value="' +
          currentLastName +
          '">',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        cancelButtonText: "Cancelar",
        preConfirm: () => {
          const first_name = document.getElementById("first_name").value;
          const last_name = document.getElementById("last_name").value;

          if (!first_name || first_name.length < 1) {
            return Swal.showValidationMessage("Se requiere un nombre");
          }
          if (!last_name || last_name.length < 1) {
            return Swal.showValidationMessage("Se requiere un apellido");
          }

          return { first_name, last_name };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const { first_name, last_name } = result.value;

          usersProvider
            .updateUser(userId, { first_name, last_name })
            .then(() => {
              setState((prevState) => ({
                ...prevState,
                users: prevState.users.map((user) =>
                  user.id === userId ? { ...user, first_name, last_name } : user
                ),
              }));
              customAlert({
                title: "Nombre y apellido actualizados",
                text: `El nombre ha sido cambiado a: ${first_name} ${last_name}`,
                icon: "success",
              });
            })
            .catch((error) => {
              const errorMessage =
                error.response?.data?.[0] ||
                error.message ||
                "Hubo un problema al actualizar el nombre.";
              customAlert({
                title: "Error",
                text: errorMessage,
                icon: "error",
              });
            });
        }
      });
    },
    [setState]
  );

  const handleEmailEdit = useCallback(
    (userId, currentEmail) => {
      customAlert({
        title: "Actualizar email",
        input: "email",
        inputValue: currentEmail,
        inputAttributes: {
          required: true,
        },
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        cancelButtonText: "Cancelar",
        inputValidator: (value) => {
          if (!value) {
            return "Debes ingresar un email";
          }
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            return "Debes ingresar un email válido";
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const newEmail = result.value;

          usersProvider
            .updateUser(userId, { email: newEmail })
            .then(() => {
              setState((prevState) => ({
                ...prevState,
                users: prevState.users.map((user) =>
                  user.id === userId ? { ...user, email: newEmail } : user
                ),
              }));
              customAlert({
                title: "Email actualizado",
                text: `El email ha sido cambiado a: ${newEmail}`,
                icon: "success",
              });
            })
            .catch((error) => {
              const errorMessage =
                error.response?.data?.[0] ||
                error.message ||
                "Hubo un problema al actualizar el email.";
              customAlert({
                title: "Error",
                text: errorMessage,
                icon: "error",
              });
            });
        }
      });
    },
    [setState]
  );

  const handleDelete = useCallback(
    (userId) => {
      customAlert({
        title: "¿Estás seguro?",
        text: "No podrás revertir esta acción.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          try {
            await usersProvider.deleteUser(userId);
            setState((prevState) => ({
              ...prevState,
              users: prevState.users.filter((user) => user.id !== userId),
              total: prevState.total - 1,
            }));

            customAlert({
              title: "Eliminado",
              text: "El gerente ha sido eliminado.",
              icon: "success",
            });
            refreshData();
          } catch (error) {
            const errorMessage =
              error.response?.data?.[0] ||
              error.message ||
              "Hubo un problema al eliminar el gerente.";
            customAlert({
              title: "Error",
              text: errorMessage,
              icon: "error",
            });
          } finally {
            setLoading(false);
          }
        }
      });
    },
    [setState, refreshData]
  );

  const handleBlockChange = useCallback(
    async (userId) => {
      const isBlocked = state.blockedUsers[userId];

      setState((prevState) => ({
        ...prevState,
        blockedUsers: {
          ...prevState.blockedUsers,
          [userId]: !isBlocked,
        },
        users: prevState.users.map((user) =>
          user.id === userId
            ? { ...user, status: !isBlocked ? "bloqueado" : "activo" }
            : user
        ),
      }));
      const newStatus = !isBlocked ? "bloqueado" : "activo";
      try {
        await usersProvider.updateUser(userId, { status: newStatus });
        refreshData();
      } catch (error) {
        const errorMessage =
          error.response?.data?.[0] ||
          error.message ||
          "Hubo un problema al actualizar el estatus del gerente.";
        customAlert({
          title: "Error",
          text: errorMessage,
          icon: "error",
        });
      }
    },
    [state.blockedUsers, refreshData]
  );

  useEffect(() => {
    setLoading(true);
    if (state.users && state.users.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.users.map((user) => {
          const isCurrentUser = user.id === currentUser.id;
          return {
            nombre: (
              <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <MKTypography variant="button" fontWeight="medium">
                  {`${user.first_name.toUpperCase()} ${user.last_name.toUpperCase()}`}
                </MKTypography>
                {!isCurrentUser && (
                  <MKButton
                    variant="outlined"
                    size="small"
                    iconOnly
                    onClick={() =>
                      handleNameEdit(user.id, user.first_name, user.last_name)
                    }
                  >
                    <EditIcon />
                  </MKButton>
                )}
              </MKBox>
            ),
            email: (
              <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <MKTypography variant="button" fontWeight="medium">
                  {user.email}
                </MKTypography>
                {!isCurrentUser && (
                  <MKButton
                    variant="outlined"
                    size="small"
                    iconOnly
                    onClick={() => handleEmailEdit(user.id, user.email)}
                  >
                    <EditIcon />
                  </MKButton>
                )}
              </MKBox>
            ),
            sucursal: (
              <MKTypography variant="button" fontWeight="medium">
                {user.subsidiary?.name || "Sin sucursal"}
              </MKTypography>
            ),
            estatus: (
              <MKTypography
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {user.status}
              </MKTypography>
            ),
            bloquear: (
              <Switch
                checked={state.blockedUsers[user.id] || false}
                onChange={() => !isCurrentUser && handleBlockChange(user.id)}
                disabled={isCurrentUser}
              />
            ),
            opciones: (
              <Box display="flex" gap={1}>
                <ActionsMenu
                  actions={[
                    {
                      icon: <DeleteIcon />,
                      text: "Eliminar",
                      onClick: () => handleDelete(user.id),
                      disabled: isCurrentUser,
                    },
                  ]}
                  disabled={isCurrentUser || state.blockedUsers[user.id]}
                />
              </Box>
            ),
          };
        }),
      }));
      setLoading(false);
    } else {
      setState((prevState) => ({
        ...prevState,
        rows: [],
      }));
      setLoading(false);
    }
  }, [
    state.users,
    state.blockedUsers,
    currentUser,
    handleNameEdit,
    handleEmailEdit,
    handleBlockChange,
    handleDelete,
  ]);

  return (
    <>
      <MKBox mb={3}>
        {" "}
        <MKButton
          variant="contained"
          size="small"
          color="primary"
          onClick={handleCreateUser}
        >
          + Agregar gerente
        </MKButton>
      </MKBox>
      <Grid container item xs={12} lg={12}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="15vh"
          >
            <VideoLoader />
          </Box>
        ) : state.rows.length === 0 ? (
          <MKTypography variant="h6" fontWeight="medium">
            No se encontraron gerentes
          </MKTypography>
        ) : (
          <Table
            columns={columns}
            rows={state.rows}
            total={total}
            page={page}
            handlePageChange={handlePageChange}
          />
        )}
      </Grid>
    </>
  );
}

export default ManagersTable;
