// React imports
import { useEffect, useState } from "react";

// External Libraries
import { useNavigate, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material UI Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

// Custom Components
import VideoLoader from "components/Animation/VideoLoader";

// Providers
import unitsProvider from "providers/unitsProvider";

function EditUnitPage() {
  // Context and hooks
  const { id } = useParams();
  const navigate = useNavigate();

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState("");

  // Data-specific states
  const [unit, setUnit] = useState({
    name: "",
    floor: "",
    block: "",
    width: "",
    height: "",
    length: "",
    default_price: "",
    status: "",
    is_irregular: false,
    square_meters: "",
  });

  // Focus states
  const [focusedWidth, setFocusedWidth] = useState(false);
  const [focusedHeight, setFocusedHeight] = useState(false);
  const [focusedLength, setFocusedLength] = useState(false);
  const [focusedPrice, setFocusedPrice] = useState(false);

  // Handlers
  const handleFocusWidth = () => setFocusedWidth(true);
  const handleBlurWidth = () => setFocusedWidth(false);
  const handleFocusHeight = () => setFocusedHeight(true);
  const handleBlurHeight = () => setFocusedHeight(false);
  const handleFocusLength = () => setFocusedLength(true);
  const handleBlurLength = () => setFocusedLength(false);
  const handleFocusPrice = () => setFocusedPrice(true);
  const handleBlurPrice = () => setFocusedPrice(false);

  // Obtener los datos de la unidad basado en el ID proporcionado y actualiza el estado
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await unitsProvider.getUnit(id);
        setUnit(data);
      } catch (error) {
        setFormErrors("Error al cargar los datos de la unidad.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  // Manejar cambios en los campos del formulario
  const handleInputChange = ({ target: { name, value } }) => {
    const newValue = ["width", "height", "length", "default_price"].includes(
      name
    )
      ? value === ""
        ? ""
        : Math.max(0, Number(value)).toString()
      : value;

    setUnit((prevUnit) => ({
      ...prevUnit,
      [name]: newValue,
    }));
  };

  // Enviar el formulario después de la validación y actualizar los datos de la unidad
  const submitForm = async (event) => {
    event.preventDefault();

    try {
      await unitsProvider.updateUnit(id, unit);
      navigate("/units", {
        state: { feedback: "Unidad actualizada correctamente" },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  return (
    <MainLayout title="Editar Unidad">
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader size="5rem" />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {formErrors && (
                  <MKAlert color="error" dismissible={true}>
                    {formErrors}
                  </MKAlert>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <MKInput
                        name="name"
                        label="Nombre"
                        value={unit.name}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        name="floor"
                        label="Piso"
                        value={unit.floor}
                        onChange={handleInputChange}
                        required={false}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        name="block"
                        label="Bloque"
                        value={unit.block}
                        onChange={handleInputChange}
                        required={false}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MKInput
                        name="width"
                        label="Ancho"
                        value={unit.width}
                        onChange={handleInputChange}
                        onFocus={handleFocusWidth}
                        onBlur={handleBlurWidth}
                        required={false}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          endAdornment:
                            focusedWidth || unit.width ? (
                              <InputAdornment
                                position="end"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                mts
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MKInput
                        name="height"
                        label="Alto"
                        value={unit.height}
                        onChange={handleInputChange}
                        onFocus={handleFocusHeight}
                        onBlur={handleBlurHeight}
                        required={false}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          endAdornment:
                            focusedHeight || unit.height ? (
                              <InputAdornment
                                position="end"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                mts
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MKInput
                        name="length"
                        label="Largo"
                        value={unit.length}
                        onChange={handleInputChange}
                        onFocus={handleFocusLength}
                        onBlur={handleBlurLength}
                        required={false}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          endAdornment:
                            focusedLength || unit.length ? (
                              <InputAdornment
                                position="end"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                mts
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="default_price"
                        label="Precio"
                        value={unit.default_price}
                        onChange={handleInputChange}
                        onFocus={handleFocusPrice}
                        onBlur={handleBlurPrice}
                        required={true}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          startAdornment:
                            focusedPrice || unit.default_price ? (
                              <InputAdornment
                                position="start"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                $
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    {unit.is_irregular && (
                      <Grid item xs={12} md={6}>
                        <MKInput
                          name="square_meters"
                          label="Metros cuadrados"
                          value={unit.square_meters}
                          onChange={handleInputChange}
                          required={false}
                          variant="standard"
                          type="number"
                          fullWidth
                          InputProps={{
                            endAdornment: unit.square_meters ? (
                              <InputAdornment
                                position="end"
                                disableTypography
                                sx={{ fontSize: "0.8rem" }}
                              >
                                m²
                              </InputAdornment>
                            ) : null,
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Actualizar
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}

export default EditUnitPage;
