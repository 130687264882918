import React, { useState } from "react";
import { Stage, Layer, Line, Circle } from "react-konva";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

const DrawingPanel = ({ open, onClose, onSave }) => {
  const [points, setPoints] = useState([]);
  const [lines, setLines] = useState([]);

  const handleStageClick = (e) => {
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();

    setPoints((prevPoints) => {
      const newPoints = [...prevPoints, pointerPosition];
      if (newPoints.length > 1) {
        const lastPoint = newPoints[newPoints.length - 2];
        const currentPoint = newPoints[newPoints.length - 1];
        setLines((prevLines) => [
          ...prevLines,
          [lastPoint.x, lastPoint.y, currentPoint.x, currentPoint.y],
        ]);
      }
      return newPoints;
    });
  };

  const handleSave = () => {
    // Calculate the minimum x and y to normalize the polygon
    const minX = Math.min(...points.map((p) => Number(p.x)));
    const minY = Math.min(...points.map((p) => Number(p.y)));

    // Normalize points by subtracting minX and minY
    // This makes the polygon start as close to 0,0 as possible
    const normalizedPoints = points.map(
      (p) => `${Number(p.x) - minX},${Number(p.y) - minY}`
    );

    normalizedPoints.push(normalizedPoints[0]); // Close the polygon

    // Pass the SVG data back
    onSave(normalizedPoints.join(" "));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Stage
          width={500}
          height={400}
          onClick={handleStageClick}
          style={{ border: "1px solid #ccc" }}
        >
          <Layer>
            {points.map((point, index) => (
              <Circle
                key={index}
                x={point.x}
                y={point.y}
                radius={1}
                fill="black"
              />
            ))}
            {lines.map((line, index) => (
              <Line
                key={index}
                points={line}
                stroke="black"
                strokeWidth={2}
                lineCap="round"
              />
            ))}
          </Layer>
        </Stage>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DrawingPanel;
