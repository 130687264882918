// React imports
import { useEffect, useState } from "react";

// React Router imports
import { useNavigate, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material UI Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

// Custom Components
import VideoLoader from "components/Animation/VideoLoader";

// Providers
import productsProvider from "providers/productsProvider";

function EditProductPage() {
  // Context and hooks
  const { id } = useParams();
  const navigate = useNavigate();

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState("");

  // Data-specific states
  const [product, setProduct] = useState({
    name: "",
    description: "",
    price: "",
    stock: "",
  });

  // Focus states
  const [focusedPrice, setFocusedPrice] = useState(false);

  // Handlers
  const handleFocusPrice = () => setFocusedPrice(true);
  const handleBlurPrice = () => setFocusedPrice(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await productsProvider.getProduct(id);
        setProduct(data);
      } catch (error) {
        setFormErrors("Error al cargar los datos del producto");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  // Handles changes in form input fields and updates product state
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "price" || name === "stock") {
      let validValue = value.replace(/[^0-9.]/g, "");
      if (validValue === "" || validValue >= 0) {
        setProduct((prevProduct) => ({
          ...prevProduct,
          [name]: validValue,
        }));
      }
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    try {
      await productsProvider.updateProduct(id, product);
      navigate("/products", {
        state: { feedback: "Producto actualizado correctamente" },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  return (
    <MainLayout title="Editar Producto">
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {formErrors && (
                  <MKAlert color="error" dismissible={true}>
                    {formErrors}
                  </MKAlert>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <MKInput
                        name="name"
                        label=""
                        value={product.name}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        name="description"
                        label="Descripción"
                        value={product.description}
                        onChange={handleInputChange}
                        required={false}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="price"
                        label="Precio"
                        value={product.price}
                        onChange={handleInputChange}
                        onFocus={handleFocusPrice}
                        onBlur={handleBlurPrice}
                        required={true}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          startAdornment:
                            focusedPrice || product.price ? (
                              <InputAdornment
                                position="start"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                $
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="stock"
                        label="Stock"
                        value={product.stock}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="number"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Actualizar
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}

export default EditProductPage;
