// React and Hooks
import { useMemo, useState } from "react";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";

// Material UI Components
import { Grid } from "@mui/material";

// Custom Components
import NavTabs from "components/NavTabs";
import TabPanel from "components/NavTabs/TabPanel";

// Pages
import ChartsTab from "pages/Dashboard/charts";
import DashboardTab from "pages/Dashboard/dashboard";

function DashboardPage() {
  // General states
  const [tab, setTab] = useState(0);

  // Derived states or memoized values
  const tabs = useMemo(
    () => [
      { label: "Dashboard", icon: "home" },
      { label: "Gráficos", icon: "leaderboard" },
    ],
    []
  );

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  return (
    <MainLayout title="Inicio">
      <Grid item xs={12} lg={12}>
        <NavTabs tabs={tabs} active={tab} onChange={handleTabChange} />
      </Grid>
      <MKBox p={3}>
        <TabPanel value={tab} index={0}>
          <DashboardTab />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ChartsTab />
        </TabPanel>
      </MKBox>
    </MainLayout>
  );
}

export default DashboardPage;
