// React imports
import { useContext, useEffect, useState } from "react";

// External Libraries
import { useNavigate, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material UI Components
import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

// Custom Components
import VideoLoader from "components/Animation/VideoLoader";

// Providers
import expenseCategoriesProvider from "providers/expenseCategoriesProvider";
import expenseProvider from "providers/expenseProvider";
import invoicesProvider from "providers/invoicesProvider";

// Contexts
import SessionContext from "contexts/SessionContext";

function EditExpensePage() {
  // Context and hooks
  const { currentUser } = useContext(SessionContext);
  const { id } = useParams();
  const navigate = useNavigate();

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState("");

  // Focus states
  const [focusedAmount, setFocusedAmount] = useState(false);
  const handleFocusAmount = () => setFocusedAmount(true);
  const handleBlurAmount = () => setFocusedAmount(false);

  // Data-specific states
  const [paymentForms, setPaymentForms] = useState([]);
  const [selectedPaymentForm, setSelectedPaymentForm] = useState(null);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [expense, setExpense] = useState({
    date: "",
    amount: "",
    concept: "",
    payment_form: "",
    notes: "",
    category: "",
    subsidiary_id: currentUser.subsidiary_id,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [paymentFormsData, expenseData, expenseCategoriesData] =
          await Promise.all([
            invoicesProvider.getPaymentForms(),
            expenseProvider.getExpense(id),
            expenseCategoriesProvider.getExpenseCategories(),
          ]);
        setPaymentForms(paymentFormsData.data || []);
        setExpenseCategories(expenseCategoriesData?.data?.data || []);
        setExpense(expenseData.data);

        const selectedForm = paymentFormsData.data.find(
          (form) => form.Value === expenseData.data.payment_form
        );
        setSelectedPaymentForm(selectedForm || null);

        const selectedCat = (expenseCategoriesData?.data?.data || []).find(
          (cat) => cat.id === expenseData.data.expense_category_id
        );
        setSelectedCategory(selectedCat || null);
      } catch (error) {
        setFormErrors("Error al cargar los datos del gasto.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleInputChange = ({ target: { name, value } }) => {
    setExpense((prevExpense) => ({
      ...prevExpense,
      [name]: value,
    }));
  };

  const handlePaymentFormChange = (_event, value) => {
    if (value) {
      setSelectedPaymentForm(value);
      setExpense((prevExpense) => ({
        ...prevExpense,
        payment_form: value.Value,
      }));
    } else {
      setSelectedPaymentForm(null);
      setExpense((prevExpense) => ({
        ...prevExpense,
        payment_form: "",
      }));
    }
  };

  const handleCategoryChange = (_ev, value) => {
    if (value) {
      setSelectedCategory(value);
      setExpense((prevExpense) => ({
        ...prevExpense,
        expense_category_id: value.id,
      }));
    } else {
      setSelectedCategory(null);
      setExpense((prevExpense) => ({
        ...prevExpense,
        expense_category_id: "",
      }));
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    try {
      await expenseProvider.updateExpense(id, expense);
      navigate("/expenses", {
        state: { feedback: "gasto actualizado correctamente" },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  return (
    <MainLayout title="Editar gasto">
      <MKBox component="section" py={12}>
        <Container>
          {loading ? (
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50vh"
            >
              <VideoLoader />
            </MKBox>
          ) : (
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {formErrors && (
                  <MKAlert color="error" dismissible>
                    {formErrors}
                  </MKAlert>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Fecha"
                        name="date"
                        type="date"
                        value={expense.date}
                        onChange={handleInputChange}
                        required
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Cantidad"
                        name="amount"
                        type="number"
                        value={expense.amount}
                        onChange={handleInputChange}
                        onFocus={handleFocusAmount}
                        onBlur={handleBlurAmount}
                        required
                        fullWidth
                        InputProps={{
                          startAdornment:
                            focusedAmount || expense.amount ? (
                              <InputAdornment
                                position="start"
                                disableTypography
                                sx={{ fontSize: "0.8rem" }}
                              >
                                $
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Concepto"
                        name="concept"
                        value={expense.concept}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedPaymentForm}
                        options={paymentForms}
                        getOptionLabel={(option) =>
                          option
                            ? `[${option.Value}] ${option.Name}`
                            : "Forma de pago"
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Forma de pago"
                            fullWidth
                          />
                        )}
                        onChange={handlePaymentFormChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Notas"
                        name="notes"
                        value={expense.notes}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedCategory}
                        options={expenseCategories}
                        getOptionLabel={(option) =>
                          option ? `${option.name}` : "Categoría"
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Categoría"
                            fullWidth
                          />
                        )}
                        onChange={handleCategoryChange}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Actualizar
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          )}
        </Container>
      </MKBox>
    </MainLayout>
  );
}

export default EditExpensePage;
