// React imports
import { useCallback, useEffect, useMemo, useState } from "react";

// React Router imports
import { Link, useLocation } from "react-router-dom";

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKDatePicker from "components/MKDatePicker";

// Custom Components
import NavTabs from "components/NavTabs";
import Toolbar from "components/Toolbar";
import customAlert from "components/Alerts/CustomAlert";

// Payment Type Tables
import DepositsTable from "pages/Payments/tabs/depositsTable";
import ProductsTable from "pages/Payments/tabs/productsTable";
import RentalsTable from "pages/Payments/tabs/rentalsTable";

// Providers
import paymentsProvider from "providers/paymentsProvider";

// Utilities
import { formatDDMMYYYY, PAYMENT_TYPES, shortDateFormat } from "utils";

function TabIndex({ defaultTab = 0, permanentQuery, urlQuery }) {
  // Context and hooks
  const location = useLocation();

  // Derived states or memoized values
  const state = useMemo(() => location.state || {}, [location.state]);
  const tabs = useMemo(
    () => [
      { label: "Rentas", icon: "home", paymentType: PAYMENT_TYPES.rental },
      {
        label: "Depósitos",
        icon: "attach_money",
        paymentType: PAYMENT_TYPES.deposit,
      },
      { label: "Productos", icon: "store", paymentType: PAYMENT_TYPES.product },
    ],
    []
  );

  // General states
  const [tab, setTab] = useState(() => {
    return sessionStorage.getItem("activeTab")
      ? Number(sessionStorage.getItem("activeTab"))
      : state.defaultTab ?? defaultTab;
  });

  const [startDate, setStartDate] = useState(
    formatDDMMYYYY(
      new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1)
    )
  );

  const [endDate, setEndDate] = useState(
    formatDDMMYYYY(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    )
  );

  const [query, setQuery] = useState(
    Object.keys(urlQuery).length > 0
      ? urlQuery
      : {
          date_gteq: startDate,
          date_lteq: endDate,
        }
  );

  // Handlers
  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  const handleDateChange = (date, type) => {
    if (type === "start") {
      setStartDate(formatDDMMYYYY(new Date(date[0])));
    } else {
      setEndDate(formatDDMMYYYY(new Date(date[0])));
    }
  };

  // Effects
  useEffect(() => {
    sessionStorage.setItem("activeTab", tab);
  }, [tab]);

  const exportPayments = useCallback(async () => {
    try {
      const { data: blobData } = await paymentsProvider.exportPayments({
        ...query,
        payment_type_matches: tabs[tab].paymentType,
      });
      const url = window.URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = `pagos-${shortDateFormat(new Date())}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      customAlert({
        title: "Error",
        text: "Hubo un problema al exportar los pagos.",
        icon: "error",
      });
    }
  }, [query, tab, tabs]);

  return (
    <>
      <Toolbar
        setQuery={(query) =>
          setQuery({ ...query, date_gteq: startDate, date_lteq: endDate })
        }
        ransackQuery="customer_first_name_or_customer_last_name_or_payable_of_Product_type_name_or_payable_of_Invoice_type_unit_name_or_status_cont"
        newItemButton={
          <Box display="flex" gap={2}>
            <MKButton
              variant="contained"
              size="small"
              color="primary"
              component={Link}
              to={`/payments/new?type=${tab}`}
            >
              + Agregar pago
            </MKButton>
            <Tooltip title="Exportar a Excel">
              <MKButton iconOnly onClick={exportPayments}>
                <DownloadIcon />
              </MKButton>
            </Tooltip>
          </Box>
        }
      >
        <MKBox display="flex" gap="1rem" alignItems="center">
          <MKDatePicker
            input={{
              value: startDate,
              label: "Fecha de inicio",
              InputLabelProps: { shrink: true },
            }}
            defaultValue={startDate}
            options={{ dateFormat: "d/m/Y", maxDate: endDate }}
            onChange={(date) => handleDateChange(date, "start")}
          />
          <MKDatePicker
            input={{
              value: endDate,
              label: "Fecha de fin",
              InputLabelProps: { shrink: true },
            }}
            defaultValue={endDate}
            options={{ dateFormat: "d/m/Y", minDate: startDate }}
            onChange={(date) => handleDateChange(date, "end")}
          />
        </MKBox>
      </Toolbar>
      <Grid item xs={12} lg={12}>
        <NavTabs tabs={tabs} active={tab} onChange={handleTabChange} />
      </Grid>
      <Grid item xs={12} lg={12}>
        {tab === 0 && (
          <RentalsTable permanentQuery={permanentQuery} query={query} />
        )}
        {tab === 1 && (
          <DepositsTable permanentQuery={permanentQuery} query={query} />
        )}
        {tab === 2 && (
          <ProductsTable permanentQuery={permanentQuery} query={query} />
        )}
      </Grid>
    </>
  );
}

export default TabIndex;
