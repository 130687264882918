// React imports
import { useMemo } from "react";

// React Router imports
import { useLocation } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Pages
import TabIndex from "pages/Invoices/Tabs/tabIndex";

function InvoicesPage({ defaultTab = 0 }) {
  // Context and hooks
  const location = useLocation();

  // Derived states or memoized values
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}_cont`] = value;
      return acc;
    }, {});
  }, [location.search]);

  return (
    <MainLayout title="Cobros">
      <TabIndex defaultTab={defaultTab} urlQuery={searchParams} />
    </MainLayout>
  );
}

export default InvoicesPage;
