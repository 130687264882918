// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";

// Pages
import InvoicesTabIndex from "pages/Invoices/Tabs/tabIndex";

function CustomerInvoicesTab({ customer }) {
  // General states
  const query = {
    customer_id_eq: customer.id,
  };

  return (
    <MKBox>
      <InvoicesTabIndex defaultTab={0} permanentQuery={query} />
    </MKBox>
  );
}

export default CustomerInvoicesTab;
