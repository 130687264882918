// React imports
import { useCallback, useEffect, useMemo, useState } from "react";

// React Router imports
import { Link, useNavigate } from "react-router-dom";

// Material Kit 2 PRO React Components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material UI Components
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import DocumentIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Custom Components
import ActionsMenu from "components/Menu";
import Table from "components/Table";
import VideoLoader from "components/Animation/VideoLoader";
import customAlert from "components/Alerts/CustomAlert";

// Providers
import rentalsProvider from "providers/rentalsProvider";

// Utilities
import { currencyFormatter, numericDateFormat, shortDateFormat } from "utils";

const CONTRACT_TEMPLATE_ID = "7273123A-49A8-4043-9413-35E09E99EE39";

function RentalsTable({ fetchData, loading }) {
  // Context and hooks
  const navigate = useNavigate();

  // Derived states or memoized values
  const columns = useMemo(
    () => [
      { name: "unidad", align: "left" },
      { name: "cliente", align: "left" },
      { name: "precio mensual", align: "left" },
      { name: "fecha inicial", align: "left" },
      { name: "fecha final", align: "left" },
      { name: "estatus", align: "left" },
      { name: "opciones", align: "center" },
    ],
    []
  );

  // Loading and error states
  const [generatingContract, setGeneratingContract] = useState(false);

  // General states
  const [contractDownloadUrl, setContractDownloadUrl] = useState(null);
  const [createdContractId, setCreatedContractId] = useState(null);
  const [state, setState] = useState({
    rentals: [],
    rows: [],
    total: 0,
    page: 0,
  });

  useEffect(() => {
    fetchData(state.page).then((data) => {
      setState((prevState) => ({
        ...prevState,
        rentals: data.rentals,
        total: data.total,
      }));
    });

    if (generatingContract && createdContractId) {
      const maxRetries = 10;
      let retries = 0;
      const interval = setInterval(async () => {
        const { data } = await rentalsProvider.getContract(createdContractId);
        retries++;
        if (data.document_card.status === "failed") {
          clearInterval(interval);
          setGeneratingContract(false);
        } else if (data.document_card.download_url) {
          clearInterval(interval);
          setContractDownloadUrl(data.document_card.download_url);
          setGeneratingContract(false);
        } else if (retries >= maxRetries) {
          clearInterval(interval);
          setGeneratingContract(false);
        }
      }, 2000);
    }

    if (contractDownloadUrl) {
      customAlert({
        title: "¡Contrato generado!",
        text: "¿Deseas descargar el contrato?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(contractDownloadUrl, "_blank");
        }
        setContractDownloadUrl(null);
      });
    }
  }, [
    state.page,
    fetchData,
    contractDownloadUrl,
    createdContractId,
    generatingContract,
  ]);

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback((_ev, page) => {
    setState((prevState) => ({ ...prevState, page }));
  }, []);

  // Deletes a rental and refreshes the rentals list
  const deleteRental = useCallback(
    async function (rentalId) {
      customAlert({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer. Se perderá toda la información del contrato.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await rentalsProvider.deleteRental(rentalId);

            const data = await fetchData(state.page);
            setState((prevState) => ({
              ...prevState,
              rentals: data.rentals,
              total: data.total,
            }));

            customAlert({
              title: "Eliminado!",
              text: "El contrato ha sido eliminado.",
              icon: "success",
            });
            navigate("/rentals");
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al eliminar el contrato.",
              icon: "error",
            });
          }
        }
      });
    },
    [fetchData, navigate, state.page]
  );

  const cancelRental = useCallback(
    async function (rentalId) {
      customAlert({
        title: "¿Estás seguro?",
        text: "Escribe el motivo de cancelación del contrato.",
        input: "text",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Cancelar contrato",
        cancelButtonText: "Mantener contrato",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await rentalsProvider.updateRental(rentalId, {
              status: "cancelado",
              motive: result.value,
            });

            const data = await fetchData(state.page);
            setState((prevState) => ({
              ...prevState,
              rentals: data.rentals,
              total: data.total,
            }));

            customAlert({
              title: "Cancelado!",
              text: "El contrato ha sido cancelado exitosamente.",
              icon: "success",
            });
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al cancelar el contrato.",
              icon: "error",
            });
          }
        }
      });
    },
    [fetchData, state.page]
  );

  const generateContract = useCallback(async (rental) => {
    setGeneratingContract(true);
    let unitMeasurement = "________";
    if (rental.unit.width && rental.unit.length) {
      if (rental.unit.height) {
        unitMeasurement = `${rental.unit.width}m de ancho x ${rental.unit.length}m de largo x ${rental.unit.height}m de alto`;
      } else {
        unitMeasurement = `${rental.unit.width}m de ancho x ${rental.unit.length}m de largo`;
      }
    }

    const data = {
      document: {
        document_template_id: CONTRACT_TEMPLATE_ID,
        status: "pending",
        payload: {
          customer: {
            name:
              rental.user.fiscal_name || rental.user_fiscal_name || "________",
            fiscal_number:
              rental.user.fiscal_number ||
              rental.user_fiscal_number ||
              "________",
            address: {
              street: rental.user.street || rental.user_street || "________",
              zip_code:
                rental.user.zip_code || rental.user_zip_code || "________",
              city: rental.user.city || rental.user_city || "________",
              neighborhood:
                rental.user.neighborhood ||
                rental.user_neighborhood ||
                "________",
            },
          },
          owner: {
            name:
              rental.subsidiary.fiscal_name ||
              rental.subsidiary_fiscal_name ||
              "________",
            fiscal_number:
              rental.subsidiary.fiscal_number ||
              rental.subsidiary_fiscal_number ||
              "________",
            email:
              rental.subsidiary.email || rental.subsidiary_email || "________",
          },
          guarantee: {
            name: rental.user_guarantee_name || "________",
          },
          unit: {
            id: rental.unit.name || rental.unit_name || "________",
            measurement: unitMeasurement,
            address: {
              street:
                rental.subsidiary.street ||
                rental.subsidiary_street ||
                "________",
              zip_code:
                rental.subsidiary.zip_code ||
                rental.subsidiary_zip_code ||
                "________",
              city:
                rental.subsidiary.city || rental.subsidiary_city || "________",
              state:
                rental.subsidiary.state ||
                rental.subsidiary_state ||
                "________",
              neighborhood:
                rental.subsidiary.neighborhood ||
                rental.subsidiary_neighborhood ||
                "________",
            },
          },
          billing: {
            name:
              rental.subsidiary.billing_name ||
              rental.billing_name ||
              "________",
            bank:
              rental.subsidiary.billing_bank ||
              rental.billing_bank ||
              "________",
            account:
              rental.subsidiary.billing_account ||
              rental.billing_account ||
              "________",
            CLABE:
              rental.subsidiary.billing_clabe ||
              rental.billing_clabe ||
              "________",
          },
          rental: {
            folio: rental.id || "________",
            start_date: numericDateFormat(rental.start_date) || "________",
            stored_goods: rental.stored_goods || "________",
            duration_months: rental.duration_months || "________",
            duration_months_text: rental.duration_months_text || "________",
            monthly_rent: rental.monthly_rent || "________",
            monthly_rent_text: rental.monthly_rent_text || "________",
            deposit: rental.deposit || "________",
            deposit_text: rental.deposit_text || "________",
            start_day: new Date(rental.start_date).getUTCDate() || "________",
            start_month:
              new Date(rental.start_date).toLocaleDateString("es-MX", {
                timeZone: "UTC",
                month: "long",
              }) || "________",
          },
        },
        meta: {
          _filename: `Contrato-${rental.id}.pdf`,
        },
      },
    };

    rentalsProvider.generateContract(data).then(({ data }) => {
      const {
        document: { id },
      } = data;
      setCreatedContractId(id);
    });
  }, []);

  useEffect(() => {
    if (state.rentals && state.rentals.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.rentals.map((rental) => ({
          unidad: (
            <MKTypography variant="button" fontWeight="medium">
              {`${rental.unit.floor || ""}${rental.unit.block || ""} ${
                rental.unit.name
              }`}
            </MKTypography>
          ),
          cliente: (
            <MKButton
              variant="text"
              color="dark"
              sx={{
                padding: 0,
                minWidth: "auto",
                fontSize: "inherit",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "primary.main",
                },
              }}
              component={Link}
              to={`/customers/${rental.user.id}#Contratos`}
            >
              {`${rental.user.first_name} ${rental.user.last_name}`}
            </MKButton>
          ),
          "precio mensual": (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(rental.monthly_rent)}
            </MKTypography>
          ),
          "fecha inicial": (
            <MKTypography variant="button" fontWeight="medium">
              {shortDateFormat(rental.start_date)}
            </MKTypography>
          ),
          "fecha final": (
            <MKTypography variant="button" fontWeight="medium">
              {shortDateFormat(rental.end_date)}
            </MKTypography>
          ),
          estatus: (
            <MKTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {rental.status}
            </MKTypography>
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  {
                    text: "Editar",
                    icon: <EditIcon />,
                    onClick: () => navigate(`/rentals/${rental.id}/edit`),
                  },
                  {
                    text: "Eliminar",
                    icon: <DeleteIcon />,
                    onClick: () => deleteRental(rental.id),
                    requiredRoles: [1, 2],
                  },
                  {
                    text: "Cancelar",
                    icon: <CancelIcon />,
                    onClick: () => cancelRental(rental.id),
                    requiredRoles: [1, 2],
                  },

                  {
                    text: "Ver Cobros",
                    icon: <VisibilityIcon />,
                    onClick: () => navigate(`/rentals/${rental.id}/invoices`),
                  },
                  {
                    text: "Generar Contrato",
                    icon: <DocumentIcon />,
                    onClick: () => generateContract(rental),
                  },
                ]}
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [state.rentals, deleteRental, navigate, generateContract, cancelRental]);

  return (
    <Grid container item xs={12} lg={12}>
      {loading || generatingContract ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="15vh"
        >
          <VideoLoader />
        </Box>
      ) : state.rows.length === 0 ? (
        <MKTypography variant="h6" fontWeight="medium">
          No se encontraron contratos
        </MKTypography>
      ) : (
        <Table
          columns={columns}
          rows={state.rows}
          total={state.total}
          page={state.page}
          handlePageChange={handlePageChange}
        />
      )}
    </Grid>
  );
}
export default RentalsTable;
