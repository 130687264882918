// React imports
import { useCallback, useContext, useEffect, useState } from "react";

// Material Kit 2 PRO React Components
import MKBox from "components/MKBox";
import MKDatePicker from "components/MKDatePicker";
import MKTypography from "components/MKTypography";

// Material UI Components
import { Box, Divider, Grid } from "@mui/material";

// Custom Components
import DashboardCard from "components/Card/DashboardCard";
import VideoLoader from "components/Animation/VideoLoader";

// Providers
import dashboardProvider from "providers/dashboardProvider";

// Utilities
import { currencyFormatter, formatDDMMYYYY } from "utils";

// Contexts
import SessionContext from "contexts/SessionContext";

function DashboardTab() {
  // Context and hooks
  const { currentUser } = useContext(SessionContext);

  // Loading and error states
  const [loadingGeneral, setLoadingGeneral] = useState(false);
  const [loadingDate, setLoadingDate] = useState(false);

  // Data-specific states
  const [generalDashboardData, setGeneralDashboardData] = useState([]);
  const [dateDashboardData, setDateDashboardData] = useState([]);

  // General states
  const [startDate, setStartDate] = useState(
    formatDDMMYYYY(
      new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1)
    )
  );
  const endingDate = formatDDMMYYYY(
    new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0)
  );

  const [endDate, setEndDate] = useState(endingDate);

  const fetchData = useCallback(async () => {
    setLoadingGeneral(true);
    const { data } = await dashboardProvider.getIndex();
    setGeneralDashboardData(data);
    setLoadingGeneral(false);
  }, []);

  const fetchDateData = useCallback(async () => {
    setLoadingDate(true);
    const { data } = await dashboardProvider.getDateData(startDate, endDate);
    setDateDashboardData(data);
    setLoadingDate(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchDateData();
  }, [fetchDateData]);

  const generalCards = [
    {
      title: "Sucursal",
      icon: "house",
      color: "primary",
      value: currentUser.subsidiary?.name || "Sin sucursal",
    },
    {
      title: "Folios fiscales",
      icon: "request_quote",
      color: "primary",
      value: currentUser.subsidiary?.folios || "0",
    },
    {
      title: "Superficie rentada",
      icon: "percent",
      color: "primary",
      value: generalDashboardData.rented_surface?.toFixed(2) + " m2" || 0,
      label: generalDashboardData.rented_percentage?.toFixed(2) + "%" || 0,
    },
    {
      title: "Área rentable",
      icon: "maps_home_work",
      color: "primary",
      value: generalDashboardData.surface?.toFixed(2) + " m2" || 0,
    },
    {
      title: "Precio promedio por m2",
      icon: "price_check",
      color: "primary",
      value: currencyFormatter.format(
        generalDashboardData.avg_price_per_m2 || 0.0
      ),
    },
    {
      title: "Pagos pendientes de revisión",
      icon: "attach_money",
      color: "warning",
      label: generalDashboardData.pending_payments_count || 0,
      value: currencyFormatter.format(
        generalDashboardData.pending_payments_amount || 0.0
      ),
      link: "/payments?status=pendiente",
    },
    {
      title: "Bodegas disponibles",
      icon: "warehouse",
      color: "success",
      value: generalDashboardData.available_units || 0,
      link: "/units?status=disponible",
    },
    {
      title: "Bodegas ocupadas",
      icon: "warehouse",
      color: "primary",
      value: generalDashboardData.rented_units || 0,
      link: "/units?status=rentada",
    },
    {
      title: "Cobros a tiempo",
      icon: "receipt",
      color: "success",
      value: generalDashboardData.on_time_invoices || 0,
      link: "/invoices?status=a+tiempo",
    },
    {
      title: "Cobros atrasados",
      icon: "receipt",
      color: "warning",
      value: generalDashboardData.delayed_invoices || 0,
      link: "/invoices?status=atrasado",
    },
    {
      title: "Clientes activos con renta",
      icon: "people",
      color: "primary",
      value: generalDashboardData.active_customers || 0,
      link: "/customers?status_eq=activo",
    },
    {
      title: "Clientes inactivos",
      icon: "people",
      color: "success",
      value: generalDashboardData.inactive_customers || 0,
      link: "/customers?status_eq=inactivo",
    },
    {
      title: "Clientes atrasados",
      icon: "people",
      color: "warning",
      value: generalDashboardData.delayed_customers || 0,
      link: "/customers?status_eq=atrasado",
    },
    {
      title: "Contratos por vencer",
      icon: "hourglass_bottom",
      color: "warning",
      value: generalDashboardData.expiring_rentals || 0,
      link: `/rentals?end_date_lt=${endingDate}`,
    },
  ];

  const dateCards = [
    {
      title: "Rentas nuevas",
      icon: "new_releases",
      color: "info",
      value: dateDashboardData.new_rentals || 0,
    },
    {
      title: "Mensualidades nuevas",
      icon: "paid",
      color: "success",
      value: currencyFormatter.format(
        dateDashboardData.new_rentals_amount || 0
      ),
    },
    {
      title: "Ingresos por rentas",
      icon: "trending_up",
      color: "info",
      value: currencyFormatter.format(dateDashboardData.rentals_income || 0),
    },
    {
      title: "Ingresos por productos",
      icon: "trending_up",
      color: "info",
      value: currencyFormatter.format(dateDashboardData.products_income || 0),
    },
    {
      title: "Ingresos totales",
      icon: "trending_up",
      color: "info",
      value: currencyFormatter.format(dateDashboardData.income || 0),
    },
  ];

  const handleDateChange = (date, type) => {
    if (type === "start") {
      setStartDate(formatDDMMYYYY(new Date(date[0])));
    } else {
      setEndDate(formatDDMMYYYY(new Date(date[0])));
    }
  };

  return (
    <MKBox>
      <MKBox mb={3}>
        <Divider />
        <MKTypography variant="h4">General</MKTypography>
        <Grid item container spacing={2}>
          {loadingGeneral ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <VideoLoader />
            </Box>
          ) : (
            generalCards.map((card, index) => (
              <Grid key={index} item xs={6} md={4} lg={3}>
                <DashboardCard
                  title={card.title}
                  icon={card.icon}
                  color={card.color}
                  value={card.value}
                  link={card.link}
                  label={card.label}
                />
              </Grid>
            ))
          )}
        </Grid>
      </MKBox>

      <MKBox mb={3}>
        <Divider />
        <MKTypography variant="h4">Por fecha</MKTypography>
        <MKBox mb={3} display="flex" gap={3} justifyContent="center">
          <MKDatePicker
            input={{
              value: startDate,
              label: "Fecha de inicio",
              InputLabelProps: { shrink: true },
            }}
            defaultValue={startDate}
            options={{ dateFormat: "d/m/Y", maxDate: endDate }}
            onChange={(date) => handleDateChange(date, "start")}
          />
          <MKDatePicker
            input={{
              value: endDate,
              label: "Fecha de fin",
              InputLabelProps: { shrink: true },
            }}
            defaultValue={endDate}
            options={{ dateFormat: "d/m/Y", minDate: startDate }}
            onChange={(date) => handleDateChange(date, "end")}
          />
        </MKBox>
        <Grid item container spacing={2}>
          {loadingDate ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <VideoLoader />
            </Box>
          ) : (
            dateCards.map((card, index) => (
              <Grid key={index} item xs={6} md={4} lg={3}>
                <DashboardCard
                  title={card.title}
                  icon={card.icon}
                  color={card.color}
                  value={card.value}
                  link={card.link}
                  label={card.label}
                />
              </Grid>
            ))
          )}
        </Grid>
      </MKBox>
    </MKBox>
  );
}

export default DashboardTab;
