// React and Hooks
import { useEffect, useState } from "react";

// External Libraries
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material UI Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Providers
import customersProvider from "providers/customersProvider";

// Custom Components
import VideoLoader from "components/Animation/VideoLoader";

// Utilities
import { CUSTOMER_STATUS } from "utils/consts";

function EditCustomerPage() {
  // Context and hooks
  const { id } = useParams();
  const navigate = useNavigate();

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  // Data-specific states
  const [customer, setCustomer] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    fiscal_number: "",
    fiscal_name: "",
    zip_code: "",
    street: "",
    exterior_number: "",
    interior_number: "",
    neighborhood: "",
    city: "",
    state: "",
    country: "",
    require_invoice: false,
    cfdi_use: "",
    fiscal_regime: "",
    status: CUSTOMER_STATUS.inactive,
  });

  // Fetches customer data based on the provided ID and updates the state accordingly
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await customersProvider.getCustomer(id);
        setCustomer(data);
      } catch (error) {
        console.error("Error fetching customer data", error);
        let errorMsg =
          "Hubo un problema al cargar los datos del cliente. Por favor, inténtalo de nuevo.";
        if (error.response && error.response.data) {
          errorMsg = Array.isArray(error.response.data)
            ? error.response.data.join(", ")
            : error.response.data;
        }
        setErrorMessage(errorMsg);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  // Handles changes in form input fields and updates customer state
  const handleInputChange = ({ target: { name, value } }) => {
    let updatedValue = name === "fiscal_number" ? value.toUpperCase() : value;

    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: updatedValue,
    }));
  };

  // Handles the change for zip code, fetching location data if valid
  const handleZipCodeChange = async (e) => {
    const zipCode = e.target.value;
    setCustomer({ ...customer, zip_code: zipCode });

    if (zipCode.length === 5) {
      try {
        const response = await axios.get(
          `https://api.zippopotam.us/mx/${zipCode}`
        );

        if (response.data) {
          const { places, country } = response.data;
          const neighborhood = places[0]["place name"];
          const state = places[0]["state"];

          setCustomer((prevState) => ({
            ...prevState,
            neighborhood: neighborhood,
            state: state,
            country: country,
          }));
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      } finally {
        setZipCodeError("");
      }
    } else {
      setZipCodeError("El código postal debe tener 5 dígitos.");
    }
  };

  // Submits the form after validating the data and handling errors
  const submitForm = async (event) => {
    event.preventDefault();

    if (zipCodeError) {
      setErrorMessage(
        "Por favor, revisa y corrige los campos incorrectos para continuar."
      );
      return;
    }

    try {
      await customersProvider.updateCustomer(id, customer);
      navigate("/customers", {
        state: { feedback: "Cliente actualizado correctamente" },
      });
    } catch (error) {
      console.error("Error updating customer", error);
      let errorMsg =
        "Hubo un problema al actualizar el cliente. Por favor, inténtalo de nuevo.";
      if (error.response && error.response.data) {
        errorMsg = Array.isArray(error.response.data)
          ? error.response.data.join(", ")
          : error.response.data;
      }
      setErrorMessage(errorMsg);
    }
  };

  return (
    <MainLayout title="Editar Cliente">
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {errorMessage && (
                  <MKAlert color="error" dismissible={true}>
                    {errorMessage}
                  </MKAlert>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="first_name"
                        label="Nombre"
                        value={customer.first_name}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="last_name"
                        label="Apellidos"
                        value={customer.last_name}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="email"
                        label="Email"
                        value={customer.email}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="email"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="phone"
                        label="Teléfono"
                        value={customer.phone}
                        onChange={handleInputChange}
                        variant="standard"
                        required={true}
                        type="tel"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="fiscal_number"
                        label="RFC"
                        value={customer.fiscal_number}
                        onChange={handleInputChange}
                        variant="standard"
                        required={true}
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="fiscal_name"
                        label="Razón Social"
                        value={customer.fiscal_name}
                        onChange={handleInputChange}
                        variant="standard"
                        required={true}
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="zip_code"
                        label="Código Postal"
                        value={customer.zip_code}
                        onChange={handleZipCodeChange}
                        variant="standard"
                        required={true}
                        type="text"
                        fullWidth
                        error={!!zipCodeError}
                        helperText={zipCodeError}
                        inputProps={{
                          maxLength: 5,
                          pattern: "[0-9]{5}",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="street"
                        label="Calle"
                        value={customer.street}
                        onChange={handleInputChange}
                        variant="standard"
                        required={true}
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="exterior_number"
                        label="Número exterior"
                        value={customer.exterior_number}
                        onChange={handleInputChange}
                        variant="standard"
                        required={true}
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="interior_number"
                        label="Número interior"
                        value={customer.interior_number}
                        onChange={handleInputChange}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="neighborhood"
                        label="Colonia"
                        value={customer.neighborhood}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            neighborhood: e.target.value,
                          })
                        }
                        variant="standard"
                        required={true}
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="city"
                        label="Ciudad"
                        value={customer.city}
                        onChange={handleInputChange}
                        variant="standard"
                        required={true}
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="state"
                        label="Estado"
                        value={customer.state}
                        onChange={(e) =>
                          setCustomer({ ...customer, state: e.target.value })
                        }
                        variant="standard"
                        required={true}
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="country"
                        label="País"
                        value={customer.country}
                        onChange={(e) =>
                          setCustomer({ ...customer, country: e.target.value })
                        }
                        variant="standard"
                        required={true}
                        type="text"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Actualizar
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}

export default EditCustomerPage;
