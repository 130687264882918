import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { useContext } from "react";
import { createBrowserHistory } from "history";

import SessionContext from "contexts/SessionContext";
import {
  adminRoutes,
  managerRoutes,
  customerRoutes,
  guestRoutes,
} from "routes";

const hist = createBrowserHistory();

export default function Router() {
  const location = useLocation();

  function useAuth() {
    return useContext(SessionContext);
  }

  function RequireAuth() {
    let auth = useAuth();
    let location = useLocation();

    if (!auth.currentUser || !auth.currentUser.id) {
      // Redirect them to the /sign-in page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/sign-in" state={{ from: location }} replace />;
    }

    return <Outlet />;
  }

  function RequireManager() {
    let auth = useAuth();

    if (auth.currentUser.role_id === 2 || auth.currentUser.role_id === 1) {
      return <Outlet />;
    }

    return <Navigate to="/" />;
  }

  function RequireAdmin() {
    let auth = useAuth();

    if (auth.currentUser.role_id === 1) {
      return <Outlet />;
    }

    return <Navigate to="/" />;
  }

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, index) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={index}
          />
        );
      }

      return null;
    });

  function Home() {
    const currentUser = useAuth().currentUser;

    if (!currentUser || !currentUser.id) {
      return <Navigate to="/sign-in" />;
    }

    if (currentUser.role_id === 3) {
      return <Navigate to={`/customers/${currentUser.id}`} />;
    }

    return <Navigate to="/dashboard" />;
  }

  return (
    <Routes history={hist}>
      <Route path="/" element={<Home />} />
      {getRoutes(guestRoutes)}
      <Route element={<RequireAuth />}>
        {getRoutes(customerRoutes)}
        <Route element={<RequireManager />}>{getRoutes(managerRoutes)}</Route>
        <Route element={<RequireAdmin />}>{getRoutes(adminRoutes)}</Route>
      </Route>
      <Route path="*" element={<Navigate to="/" state={location.state} />} />
    </Routes>
  );
}
