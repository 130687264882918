// Material Kit 2 PRO React base styles
import colors from "assets/theme/base/colors";

// Material Kit 2 PRO React helper functions
import rgba from "assets/theme/functions/rgba";

const { info, white, gradients } = colors;

export const flatpickr = {
  ".flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus":
    {
      background: rgba(info.main, 0.28) + " !important",
      color: "black !important",
      border: "none",
    },

  ".flatpickr-day.today": {
    background: "transparent !important",
    color: "black !important",
    border: "none !important",
  },

  ".flatpickr-day.today:hover, .flatpickr-day.today:focus": {
    background: rgba(info.main, 0.28) + " !important",
    color: "black !important",
    border: "none !important",
  },

  ".flatpickr-day.today.disabled": {
    background: "transparent !important",
    color: rgba(info.main, 0.5) + " !important",
    cursor: "not-allowed !important",
    pointerEvents: "none !important",
    border: "none",
  },

  ".flatpickr-day.selected, .flatpickr-day.selected:hover, .flatpickr-day.nextMonthDay.selected, .flatpickr-day.nextMonthDay.selected:hover, .flatpickr-day.nextMonthDay.selected:focus":
    {
      background: `${gradients.info.state} !important`,
      color: white.main + " !important",
      border: "none",
    },

  ".flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg":
    {
      color: `${info.main} !important`,
      fill: `${info.main} !important`,
    },
};

export default flatpickr;
