// React imports
import { useContext, useEffect, useState } from "react";

// React Router imports
import { useLocation, useNavigate } from "react-router-dom";

// Material Kit 2 PRO React Components
import MKAlert from "components/MKAlert";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

// Material UI Components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Custom Components
import LogoAnimation from "components/Animation/LogoAnimation";

// Layouts
import AuthLayout from "layouts/authLayout";

// Providers
import authProviderInstance from "providers/authProvider";

// Contexts
import SessionContext from "contexts/SessionContext";

function SignInSimple() {
  // Context and hooks
  const { setCurrentUser } = useContext(SessionContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  // Loading and error states
  const [isAnimating, setIsAnimating] = useState(true);
  const [formErrors, setFormErrors] = useState("");

  // General states
  const [rememberMe, setRememberMe] = useState(true);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  // Redirect if there's a signed in user
  useEffect(() => {
    const storedUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (storedUser && storedUser.id) {
      if (state && state.from) {
        navigate(state.from);
      } else {
        navigate("/");
      }
    }
  }, [navigate, state]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: loggedUser } = await authProviderInstance.login(loginData);
      setCurrentUser(loggedUser);
      sessionStorage.setItem("currentUser", JSON.stringify(loggedUser));
      if (rememberMe) {
        localStorage.setItem("currentUser", JSON.stringify(loggedUser));
      }

      navigate("/", {
        state: {
          feedback: `Bienvenido ${loggedUser.first_name} ${loggedUser.last_name}`,
        },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.Error;
      }
      setFormErrors(errorMsg);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  return (
    <AuthLayout>
      {isAnimating && <LogoAnimation onComplete={handleAnimationComplete} />}
      {!isAnimating && (
        <Card>
          <MKBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            pt={2.5}
            pb={2.875}
            px={2.5}
            textAlign="center"
            lineHeight={1}
          >
            <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Inicia sesión
            </MKTypography>
          </MKBox>
          <MKBox p={3}>
            <MKBox
              component="form"
              role="form"
              method="post"
              onSubmit={handleSubmit}
            >
              {formErrors && (
                <MKAlert color="error" dismissible={true}>
                  {formErrors}
                </MKAlert>
              )}
              <MKBox mb={2}>
                <MKInput
                  type="email"
                  label="Email"
                  name="email"
                  fullWidth
                  onChange={handleInputChange}
                />
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  type="password"
                  label="Contraseña"
                  name="password"
                  fullWidth
                  onChange={handleInputChange}
                />
              </MKBox>
              <MKBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Recuérdame
                </MKTypography>
              </MKBox>
              <MKBox mt={2} mb={1}>
                <MKButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  fullWidth
                >
                  ingresar
                </MKButton>
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
      )}
    </AuthLayout>
  );
}

export default SignInSimple;
