/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { NavLink } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { Icon } from "@mui/material";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function NavbarItem({
  name,
  icon,
  route,
  mobileName,
  collapse,
  isMobile,
  ...rest
}) {
  const theme = useTheme();

  const routeComponent = {
    component: NavLink,
    to: route,
    style: ({ isActive }) => {
      return {
        fontWeight: isActive ? "bold" : "regular",
        color: isActive ? theme.palette.primary.main : "dark",
      };
    },
  };

  return (
    <>
      <MKBox
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        borderRadius="md"
        opacity={0.6}
        sx={{
          cursor: "pointer",
          userSelect: "none",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
        {...(route && routeComponent)}
      >
        {icon && (
          <MKTypography
            variant="body2"
            lineHeight={1}
            color="inherit"
            sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
          >
            {icon}
          </MKTypography>
        )}
        {(name || mobileName) && (
          <MKTypography
            variant="button"
            textTransform="capitalize"
            color="inherit"
            sx={{
              fontWeight: "inherit",
              ml: 1,
              mr: 0.25,
            }}
          >
            {isMobile ? mobileName || name : name || mobileName}
          </MKTypography>
        )}
        {collapse && (
          <MKTypography variant="body2" color="inherit" ml="auto">
            <Icon sx={{ fontWeight: "normal", verticalAlign: "middle" }}>
              {collapse && "keyboard_arrow_down"}
            </Icon>
          </MKTypography>
        )}
      </MKBox>
    </>
  );
}

// Setting default values for the props of NavbarItem
NavbarItem.defaultProps = {
  route: "",
};

// Typechecking props for the NavbarItem
NavbarItem.propTypes = {
  name: PropTypes.string,
  mobileName: PropTypes.string,
  icon: PropTypes.node,
  isMobile: PropTypes.bool,
  route: PropTypes.string,
  collapse: PropTypes.bool,
};

export default NavbarItem;
