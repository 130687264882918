// React imports
import { useMemo } from "react";

// React Router imports
import { useLocation } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Pages
import TabIndex from "pages/Payments/tabIndex";

function PaymentsPage({ defaultTab = 0 }) {
  // Context and hooks
  const location = useLocation();

  // Derived states or memoized values
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      if (key === "invoice_id") {
        acc["payable_of_Invoice_type_id_eq"] = value;
      } else {
        acc[`${key}_cont`] = value;
      }
      return acc;
    }, {});
  }, [location.search]);

  return (
    <MainLayout title="Pagos">
      <TabIndex defaultTab={defaultTab} urlQuery={searchParams} />
    </MainLayout>
  );
}

export default PaymentsPage;
